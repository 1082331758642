import createReactComponent from '../createReactComponent';
export default createReactComponent('heart-minus', 'IconHeartMinus', [
  [
    'path',
    {
      d: 'M19.5 12.572l-2.494 2.47m-5.006 4.958l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 19h6', key: 'svg-1' }],
]);
