import createReactComponent from '../createReactComponent';
export default createReactComponent('heart-up', 'IconHeartUp', [
  [
    'path',
    {
      d: 'M12 20l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.893 6.139',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 22v-6', key: 'svg-1' }],
  ['path', { d: 'M22 19l-3 -3l-3 3', key: 'svg-2' }],
]);
