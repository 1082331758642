import createReactComponent from '../createReactComponent';
export default createReactComponent('heart-question', 'IconHeartQuestion', [
  [
    'path',
    {
      d: 'M14.105 17.915l-2.105 2.085l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 0 1 8.524 5.127',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 22v.01', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-2',
    },
  ],
]);
