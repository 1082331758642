import createReactComponent from '../createReactComponent';
export default createReactComponent('heart-share', 'IconHeartShare', [
  [
    'path',
    {
      d: 'M19.5 12.572l-.468 .464m-6.077 6.019l-.955 .945l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 22l5 -5', key: 'svg-1' }],
  ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-2' }],
]);
