import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'hexagonal-pyramid-plus',
  'IconHexagonalPyramidPlus',
  [
    [
      'path',
      {
        d: 'M18.642 12.04l-5.804 -9.583a.996 .996 0 0 0 -1.676 0l-7.846 12.954a1.988 1.988 0 0 0 .267 2.483l2.527 2.523c.374 .373 .88 .583 1.408 .583h4.982',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M12 2l-5 18.9', key: 'svg-1' }],
    ['path', { d: 'M12 2l3.304 12.489', key: 'svg-2' }],
    ['path', { d: 'M16 19h6', key: 'svg-3' }],
    ['path', { d: 'M19 16v6', key: 'svg-4' }],
  ],
);
