import createReactComponent from '../createReactComponent';
export default createReactComponent('heart-pause', 'IconHeartPause', [
  [
    'path',
    {
      d: 'M19.5 12.572l-.784 .777m-5.725 5.67l-.991 .981l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M17 17v5', key: 'svg-1' }],
  ['path', { d: 'M21 17v5', key: 'svg-2' }],
]);
