import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'heart-exclamation',
  'IconHeartExclamation',
  [
    [
      'path',
      {
        d: 'M15.03 17l-3.03 3l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.922 6.102',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M19 16v3', key: 'svg-1' }],
    ['path', { d: 'M19 22v.01', key: 'svg-2' }],
  ],
);
