import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'irregular-polyhedron-off',
  'IconIrregularPolyhedronOff',
  [
    [
      'path',
      {
        d: 'M4.706 4.73a1 1 0 0 0 -.458 1.14l1.752 6.13l-1.752 6.13a1 1 0 0 0 .592 1.205l6.282 2.503a2.46 2.46 0 0 0 1.756 0l6.282 -2.503c.04 -.016 .079 -.035 .116 -.055m-.474 -4.474l-.802 -2.806l1.752 -6.13a1 1 0 0 0 -.592 -1.205l-6.282 -2.503a2.46 2.46 0 0 0 -1.756 0l-3.544 1.412',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M4.5 5.5c.661 .214 1.161 .38 1.5 .5m6 2c.29 -.003 .603 -.06 .878 -.17l6.622 -2.33',
        key: 'svg-1',
      },
    ],
    [
      'path',
      {
        d: 'M6 12l5.21 1.862a2.34 2.34 0 0 0 1.58 0l.742 -.265m2.956 -1.057c.312 -.11 .816 -.291 1.512 -.54',
        key: 'svg-2',
      },
    ],
    ['path', { d: 'M12 22v-10', key: 'svg-3' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
  ],
);
