import createReactComponent from '../createReactComponent';
export default createReactComponent('http-que', 'IconHttpQue', [
  [
    'path',
    {
      d: 'M5 8a2 2 0 0 1 2 2v4a2 2 0 1 1 -4 0v-4a2 2 0 0 1 2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 15l1 1', key: 'svg-1' }],
  ['path', { d: 'M21 8h-4v8h4', key: 'svg-2' }],
  ['path', { d: 'M17 12h2.5', key: 'svg-3' }],
  ['path', { d: 'M10 8v6a2 2 0 1 0 4 0v-6', key: 'svg-4' }],
]);
