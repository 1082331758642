import createReactComponent from '../createReactComponent';
export default createReactComponent('heart-cancel', 'IconHeartCancel', [
  [
    'path',
    {
      d: 'M12 20l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 0 1 7.993 6.01',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
  ['path', { d: 'M17 21l4 -4', key: 'svg-2' }],
]);
