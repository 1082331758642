import createReactComponent from '../createReactComponent';
export default createReactComponent('heart-down', 'IconHeartDown', [
  [
    'path',
    {
      d: 'M12 20l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.907 6.12',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 16v6', key: 'svg-1' }],
  ['path', { d: 'M22 19l-3 3l-3 -3', key: 'svg-2' }],
]);
